body{
    font-family: 'Roboto';
}
h1,h2,h3,h4,h5,h6{
    font-family: 'Oswald' !important;
}
.bg-dark-primary{
    background-color: #242f52;
}
.table > :not(:first-child), .dataTable-table > :not(:first-child){
    border-top:none !important;
}
.pl-15{
    padding-left: 15px !important;
} 
.pr-15{
    padding-right: 15px !important;
}
.mr-10{
    margin-right: 10px;
}
.mr-5{
    margin-right: 5px;
}
.reject-btn{
    padding-left: 0.7em !important;
    padding-right: 0.7em !important;
}
.custom-btns{
    background-color: #242f52 !important;
}
.card-hcontent{
    font-weight: 700;
    font-size: 15px;
}
.card-action-area:hover{
    color:inherit;
}
.no-border{
    border: none !important;
}
.header-bg{
    background-image: url("../assets/img/bg-masthead.webp");
    background-color: #087ab8;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
}
.loader-container {
    background: url("../assets/img/Loading_icon_1.gif") center no-repeat !important;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 1;
    background-size: 230px !important;
}
.admin-loader-container{
    background: url("../assets/img/Loading_icon_1.gif") 43% no-repeat !important;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 1;
    background-size: 230px !important;
}
.vehicle_list_tbl .rdt_TableHeadRow{
    background-color: #212529;
    color: white;
    margin-top: 15px;
}
.vdetail_image{
    border: 1px solid #D3D3D3;
    border-radius: 5px;
}
.title-border{
    border-bottom: 1px solid #D3D3D3;
}
.vehicle_details .vdetail_list{
    list-style: none;
    outline: none;
    padding: 0;
    margin: 0;
}
.vehicle_details .vdetail_list .vdetail_item{
    list-style: none;
    padding: 0;
    margin: 4px 0;
    font-size: 13px;
}
.vehicle_details .vdetail_list .vdetail_item .vdetail_item_label{
    width: 50%;
    display: inline-block;
    color: #444;
    font-weight: 500 !important;
    font-size: 100% !important;
    padding: 0 !important;
    margin: 0;
    text-align: left;
}
.admin_vehicle_details .vdetail_list{
    list-style: none;
    outline: none;
    padding: 0;
    margin: 0;
}
.admin_vehicle_details .vdetail_list .vdetail_item{
    list-style: none;
    padding: 0;
    margin: 4px 0;
    font-size: 13px;
}
.admin_vehicle_details .vdetail_list .vdetail_item .vdetail_item_label{
    width: 30%;
    display: inline-block;
    color: #444;
    font-weight: 500 !important;
    font-size: 100% !important;
    padding: 0 !important;
    margin: 0;
    text-align: left;
}
.fuel-tbl{
    overflow-y: auto;
    height: 215px !important;
}
.fuel-tbl th{
    font-size: 13px;
}
.fuel-tbl td{
    font-size: 12px;
}
.home-vehicle-heading{
    color: rgb(8, 122, 184);
    min-height: auto;
    font-family: Oswald;
    letter-spacing: 0.4px;
    font-size: 36px;
    text-align: center;
    font-family: 'Oswald', sans-serif;
}
.home-vehicle-sec .divider{
    margin-top: 5px;
}
.home-vehicle-sec .viewall-btn{
    display: block;
    text-align: center;
    text-decoration: none;
}

.custom-head-btn{
    background-color: #083f7feb !important;
}
#mainNav{
    background-color: #083f7feb !important;
}
/* #layoutSidenav{
    background-color: #5050502d;
} */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
body{
    font-family: 'Roboto', sans-serif;
}
.list-card-img{
    height: 200px;
    object-fit: contain;
}
.list-card-title{
    color: #4f4f4f;
    text-decoration: none;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 1.2rem;
}
.list-card-title:hover{
    color: #3a3a3a;
}
.list-card-desc span{
    display: block;
    font-size: 0.9rem;
}
.custom-btn{
    background-color: #3b71ca;
    color: #fff;
    box-shadow: 0 4px 9px -4px #3b71ca;
    text-transform: uppercase;
    vertical-align: bottom;
    border: 0;
    box-shadow: 0 4px 9px -4px rgb(0 0 0 / 35%);
    font-weight: 500;
    padding: 0.625rem 1.5rem 0.5rem;
    font-size: .75rem;
    line-height: 1.5;
}
.fuel-history-tbl th{
    font-size: 0.9rem;
}
.fuel-history-tbl td{
    font-size: 0.87rem;
}
.vmodel,.vreg-no{
    font-size: 0.87rem;
    display: block;
}
/* Login Page */
.btn-login {
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    padding: 0.75rem 1rem;
  }
.MuiTablePagination-displayedRows{
    margin-top: 1rem !important;
}
.css-19kzrtu{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.MuiTablePagination-selectLabel{
    margin-block-start: 1em;
    margin-block-end: 1em;
}
.pl-0{
    padding-left: 0px !important;
}
.admin-custom-nav .nav-link{
    font-size: 15px;
}
.admin-custom-nav  .user-nav-items{
    display: flex;
    align-items: center;
}
.fs-15{
    font-size: 15px;
}
.border-white{
    border: 1px solid #fff;
}
.text-red {
    color: red;
}
.fs-13{
    font-size: 13px;
}

.error-not-found-container
{
    width: 100%;
    height: 100vh;
}

.error-not-found-container .error_block
{
    width: 40%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
}

.error-not-found-container .error_block h1
{
    font-size: 80px;
    color: #3f3a64;
}

.error-not-found-container .error_block h3
{
    font-size: 40px;
    color: #3f3a64;
}

.error-not-found-container .error_btn
{
    width: 250px;
    height: 45px;
    background: #20ad96;
    border:none;
    border-radius: 5px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-top: 30px;
    cursor: pointer;
}